import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReachText from '../../../components/reach-text';

import styles from '../index.module.scss';

const Cover = ({ currentTab, node, changedTab, CustomMeta }) => {
  const { url, alt = 'Category Image' } = get(node, 'top_image', {});
  
  switch (currentTab) {
    case 'tldr':
      return (
        <div className={styles.cardTldr}>
          <ReachText input={node.body} renderPageTldr renderMini />
        </div>
      );
    case 'table':
      return (
        <div className={styles.cardTable}>
          <ReachText input={node.body} renderPageTable renderMini />
        </div>
      );
    case 'pie-chart':
      return (
        <div className={styles.cardChart}>
          <ReachText input={node.body} renderPagePieChart renderMini />
        </div>
      );
    case 'bar-chart':
      return (
        <div className={styles.cardChart}>
          <ReachText input={node.body} renderPageBarChart renderMini />
        </div>
      );
    case 'meta':
    default:
      if (CustomMeta) {
        return <CustomMeta />;
      }
      return (
        <img 
          alt={alt}
          className={styles.cardImage}
          {...(changedTab ? {src: url} : {'data-src': url})} 
        />
      );
  }
};


Cover.propTypes = {
  node: PropTypes.object,
  CustomMeta: PropTypes.node,
  changedTab: PropTypes.bool,
  currentTab: PropTypes.string,
};

Cover.defaultProps = {
  node: {},
  CustomMeta: null,
  currentTab: false,
  changedTab: 'meta',
};

export default Cover;

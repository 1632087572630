import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import PageList from './components/page-list';
import PageTldr from './components/page-tldr';
import PageClose from './components/page-close';
import PageIntro from './components/page-intro';
import PageTable from './components/page-table';
import PagePieChart from './components/page-pie-chart';
import PageBarChart from './components/page-bar-chart';
import { findPageLabel, getReachText } from '../../utils/reach-text-utils';

const ReachText = ({
  input,
  renderPageTldr,
  renderPageIntro,
  renderPageList,
  renderPagePieChart,
  renderPageBarChart,
  renderPageTable,
  renderPageClose,
  renderMini,
}) => {
  const reachTextInput = getReachText(input);

  if (isEmpty(reachTextInput)) {
    return null;
  }

  const pageTldr = renderPageTldr && findPageLabel(reachTextInput, 'page-tldr');
  const pageIntro = renderPageIntro && findPageLabel(reachTextInput, 'page-intro');
  const pageList = renderPageList && findPageLabel(reachTextInput, 'page-list');
  const pagePieChart = renderPagePieChart && findPageLabel(reachTextInput, 'page-pie-chart');
  const pageBarChart = renderPageBarChart && findPageLabel(reachTextInput, 'page-bar-chart');
  const pageTable = renderPageTable && findPageLabel(reachTextInput, 'page-table');
  const pageClose = renderPageClose && findPageLabel(reachTextInput, 'page-close');

  return (
    <div>
      {pageTldr && <PageTldr fields={get(pageTldr, 'fields', [])} renderMini={renderMini} />}
      {pageIntro && <PageIntro fields={get(pageIntro, 'fields', [])} renderMini={renderMini} />}
      {pageList && <PageList fields={get(pageList, 'fields', [])} renderMini={renderMini} />}
      {pagePieChart && <PagePieChart fields={get(pagePieChart, 'fields', [])} renderMini={renderMini} />}
      {pageBarChart && <PageBarChart fields={get(pageBarChart, 'fields', [])} renderMini={renderMini} />}
      {pageTable && <PageTable fields={get(pageTable, 'fields', [])} renderMini={renderMini} />}
      {pageClose && <PageClose fields={get(pageClose, 'fields', [])} renderMini={renderMini} />}
    </div>
  );
};

ReachText.propTypes = {
  input: PropTypes.array,
  renderPageTldr: PropTypes.bool,
  renderPageIntro: PropTypes.bool,
  renderPageList: PropTypes.bool,
  renderPagePieChart: PropTypes.bool,
  renderPageBarChart: PropTypes.bool,
  renderPageTable: PropTypes.bool,
  renderPageClose: PropTypes.bool,
  renderMini: PropTypes.bool,
};

ReachText.defaultProps = {
  input: [],
  renderPageTldr: false,
  renderPageIntro: false,
  renderPageList: false,
  renderPagePieChart: false,
  renderPageBarChart: false,
  renderPageTable: false,
  renderPageClose: false,
  renderMini: false,
};

export default ReachText;

import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ReachTextResolver from '../../../reach-text-resolver';

import styles from './index.module.scss';

const PageClose = ({ fields }) => {
  if (isEmpty(fields)) {
    return null;
  }

  return (
    <div className={`page-close ${styles.pageClose}`}>
      <span className={styles.pageCloseRibbon}>Closing</span>
      {fields.map((field, i) => (
        <React.Fragment key={`page-close-${i}`}>
          {get(field, 'reach_text', []).map((input, j) => (
            <ReachTextResolver
              key={`page-close-inner-${j}`}
              input={input}
              typeAttributes={{
                heading3: {
                  style: {
                    fontWeight: 600,
                    fontSize: 22,
                  },
                },
              }}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

PageClose.propTypes = {
  fields: PropTypes.array,
};

PageClose.defaultProps = {
  fields: [],
};

export default PageClose;

import get from 'lodash/get';
import map from 'lodash/map';
import { Table } from 'antd';
import trim from 'lodash/trim';
import find from 'lodash/find';
import tail from 'lodash/tail';
import first from 'lodash/first';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import lowerCase from 'lodash/lowerCase';
import capitalize from 'lodash/capitalize';

import styles from './index.module.scss';

const toArray = commaSeparatedString =>
  compact(commaSeparatedString.split(/\s?,\s?/).map(str => trim(str)));

const PageTable = ({ fields, renderMini }) => {
  const [sortedInfo, setSortedInfo] = useState({});

  if (isEmpty(fields)) {
    return null;
  }

  const textNodes = get(fields, '[0].reach_text', []);
  const heading = (find(textNodes, ({ type }) => type.includes('heading')) || {}).text;
  const tableRowsData = textNodes.filter(({ type }) => type === 'paragraph');

  const tableData = compact(map(tableRowsData, 'text'));
  if (isEmpty(tableData) || tableData.length === 1) {
    return null;
  }

  const columns = toArray(first(tableData) || '').map(colName => {
    const key = lowerCase(colName);
    return {
      key,
      dataIndex: key,
      title: capitalize(colName),
      sorter(a, b) {
        try {
          const [_a, _b] = [parseFloat(a[key]), parseFloat(b[key])];
          if (!isNaN(_a) && !isNaN(_b)) {
            return _a - _b;
          }
          throw new Error('Compare Strings');
        } catch (e) {
          if (a[key] > b[key]) return 1;
          if (a[key] < b[key]) return -1;
          return 0;
        }
      },
      sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
    };
  });

  if (isEmpty(columns)) {
    return null;
  }

  const dataSource = (tail(tableData) || '').map((rowString, rowIndex) => {
    const rowData = toArray(rowString);
    return rowData.reduce((acc, row, colIndex) => ({
      ...acc,
      key: rowIndex,
      [columns[colIndex].key]: row,
    }), {});
  });

  const onTableChange = (_, __, sorter) => setSortedInfo(sorter);

  const headingStyle = renderMini ? {fontSize: 18} : {};

  return (
    <div className="page-table">
      {heading && (
        <>
          <h3 className={styles.heading} style={headingStyle}>{heading}</h3>
          <hr className={styles.divider} />
        </>
      )}
      <Table
        columns={columns}
        pagination={false}
        dataSource={dataSource}
        onChange={onTableChange}
        size={renderMini ? 'small' : 'middle'}
      />
    </div>
  );
};

PageTable.propTypes = {
  fields: PropTypes.array,
  renderMini: PropTypes.bool,
};

PageTable.defaultProps = {
  fields: [],
  renderMini: false,
};

export default PageTable;

import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import ReadMoreButton from './read-more-button';

import styles from '../index.module.scss';

const MetaDescription = ({ node }) => {
    const uid = get(node, '_meta.uid', '');
    const category = get(node, 'category', '');
    const description = get(node, 'description[0].text', '');
    return (
        <div>
            <div className={styles.cardDescription}>{description}</div>
            <ReadMoreButton linkTo={`/${category}/${uid}`} />
        </div>
    );
};

MetaDescription.propTypes = {
    node: PropTypes.object,
};

export default MetaDescription;
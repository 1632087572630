import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { MdEmail } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiFillFacebook } from 'react-icons/ai';
import { FaTumblrSquare, FaTwitterSquare, FaTelegram, FaLinkedin, FaPinterest } from 'react-icons/fa';

import { share, shareFacebook, shareLinkedin, sharePinterest, shareTelegram, shareTumblr, shareTwitter, shareWhatsapp } from '../../../utils/share-utils';

import styles from '../index.module.scss';

const SocialShare = ({ node, visible }) => {
  const uid = get(node, '_meta.uid', '');
  const category = get(node, 'category', '');
  const { url } = get(node, 'top_image', {});

  const _title = get(node, 'title[0].text', '');
  const _description = get(node, 'description[0].text', '');
  const _linkTo = typeof window !== `undefined` ? `${window.location.origin}/${category}/${uid}` : '/';

  const title = encodeURIComponent(_title);
  const linkTo = encodeURIComponent(_linkTo);
  const description = encodeURIComponent(_description);

  const mailSubject = encodeURIComponent(`Check out "${_title}"`);
  const mailBody = `${encodeURIComponent(`
    Thought you would like this post on ${typeof window !== `undefined` ? window.location.origin : ''}.
    Title: ${_title}.
    Description: ${_description}.
  `)}` + '\n\n' + _linkTo;

  return (
    <div 
      data-social-share
      className={`${styles.social} ${visible ? styles.socialVisible : ''}`} 
    >
      <AiFillFacebook
        key="facebook"
        data-social-share
        className={styles.socialFacebook}
        onClick={() => shareFacebook(`u=${linkTo}&text=${title}`)}
      />
      <FaTwitterSquare
        key="twitter"
        data-social-share
        className={styles.socialTwitter}
        onClick={() => shareTwitter(`url=${linkTo}&text=${title}`)}
      />
      <FaTumblrSquare
        key="tumblr"
        data-social-share
        className={styles.socialTumblr}
        onClick={() => shareTumblr(`url=${linkTo}`)}
      />
      <FaLinkedin
        key="linkedin"
        data-social-share
        className={styles.socialTwitter}
        onClick={() => shareLinkedin(`url=${linkTo}&title=${title}&summary=${description}&source=LinkedIn`)}
      />
      <FaPinterest
        key="pinterest"
        data-social-share
        className={styles.socialPinterest}
        onClick={() => sharePinterest(`url=${linkTo}&media=${url}&description=${description}`)}
      />
      <IoLogoWhatsapp
        key="whatsapp"
        data-social-share
        className={styles.socialWhatsapp}
        onClick={() => shareWhatsapp(`text=${linkTo}`)}
      />
      <FaTelegram
        key="telegram"
        data-social-share
        className={styles.socialTelegram}
        onClick={() => shareTelegram(`url=${linkTo}&text=${title}`)}
      />
      <MdEmail
        key="mail"
        data-social-share
        className={styles.socialMail}
        onClick={() => share('mailto:')(`subject=${mailSubject}&body=${mailBody}`)}
      />
    </div>
  );
};

SocialShare.propTypes = {
  node: PropTypes.object,
  visible: PropTypes.bool,
};

SocialShare.defaultProps = {
  node: {},
  visible: false,
};

export default SocialShare;

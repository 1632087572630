import React from 'react';
import take from 'lodash/take';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import ReachTextResolver from '../../../../../reach-text-resolver';
import styles from './page-list-item.module.scss';

const PageListItem = ({ fields }) => {
  if (isEmpty(fields)) {
    return null;
  }
  if (fields.length < 2) {
    return null;
  }

  let _fields = cloneDeep(fields);

  const { logoField, h2Field } = (() => {
    const firstTwoFields = take(_fields, 2);
    const resp = { logoField: null, h2Field: null };
    resp.logoField = firstTwoFields.find(f => f.type === 'image');
    resp.h2Field = firstTwoFields.find(f => f.type === 'heading2');
    return resp;
  })();

  const fieldsToSlice = (logoField ? 1 : 0) + (h2Field ? 1 : 0);

  return (
    <div className={`page-list-item ${styles.pageListItem}`}>
      <div className={styles.pageListItemHeader}>
        <ReachTextResolver
          input={h2Field}
          attributes={{ id: h2Field.text }}
        />
        <ReachTextResolver
          input={logoField}
          attributes={{
            className: styles.pageListItemLogo,
          }}
        />
      </div>

      <Divider className={styles.divider} />

      {_fields.slice(fieldsToSlice).map((field, i) => (
        <ReachTextResolver
          key={`${field.type}-${i}`}
          input={field}
          typeAttributes={{
            image: {
              className: styles.pageListItemImg,
            },
          }}
        />
      ))}
    </div>
  );
};

PageListItem.propTypes = {
  fields: PropTypes.array,
};

PageListItem.defaultProps = {
  fields: [],
};

export default PageListItem;

const ApolloLinkTimeout = require('apollo-link-timeout').default;
const { InMemoryCache } = require('apollo-cache-inmemory');
const { PrismicLink } = require('apollo-link-prismic');
const { ApolloClient } = require('apollo-client');
const isFunction = require('lodash/isFunction');

const { PRISMIC_TOKEN } = require('../constants');

const timeoutLink = new ApolloLinkTimeout(10000);
const prismicLink = PrismicLink({
  uri: 'https://datayears-cms.prismic.io/graphql',
  accessToken: PRISMIC_TOKEN,
});

const cache = new InMemoryCache();
const link = timeoutLink.concat(prismicLink);

const getClient = async (predefineCache = null) => {
  if (isFunction(predefineCache)) {
    await predefineCache(cache);
  }
  return new ApolloClient({ link, cache });
};

module.exports = getClient;

import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import trim from 'lodash/trim';
import tail from 'lodash/tail';
import first from 'lodash/first';
import PropTypes from 'prop-types';
import sample from 'lodash/sample';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

const defs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    size: 4,
    padding: 1,
    stagger: true,
  },
  {
    id: 'lines',
    type: 'patternLines',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    rotation: -45,
    lineWidth: 6,
    spacing: 10,
  },
];

const PageBarChart = ({ fields, renderMini }) => {
  const chartEl = useRef(null);
  const [chartStyle, setChartStyle] = useState({});

  useEffect(function setChartHeightIfNotSet() {
    const chartHeight = window.getComputedStyle(chartEl.current).height;
    if (!chartHeight || chartHeight === '100%' || parseFloat(chartHeight) < 200) {
      setChartStyle({ height: renderMini ? 350 : 380 });
    }
  }, [chartEl]);

  if (isEmpty(fields)) {
    return null;
  }

  const textNodes = get(fields, '[0].reach_text', []);
  const heading = (find(textNodes, ({ type }) => type.includes('heading')) || {}).text;
  const chartRawData = textNodes.filter(({ type }) => type === 'paragraph');

  // ["index, ticker, p/e, eps", "apple, APPL, 20, 10", "tesla, TSLA, -, -"]
  const barChartData = compact(map(chartRawData, 'text'));
  if (isEmpty(barChartData) || barChartData.length === 1) {
    return null;
  }

  const barChartKeys = tail(first(barChartData).split(',').map(str => trim(str)));
  if (isEmpty(barChartKeys)) {
    return null;
  }

  const data = tail(barChartData).map(dataString => {
    const barChartValues = compact(dataString.split(',').map(str => trim(str)));
    const [index, ...values] = barChartValues;
    return barChartKeys.reduce(
      (acc, key, i) => ({ ...acc, [key]: values[i] }),
      { index }
    );
  });

  const headingStyle = renderMini ? {fontSize: 18} : {};

  return (
    <div
      className={`page-bar-chart ${styles.container}`}
      style={{...chartStyle, maxWidth: renderMini ? '92vw' : 'inherit'}}
      ref={chartEl}
    >
      {heading && (
        <>
          <h3 className={styles.heading} style={headingStyle}>{heading}</h3>
          <hr className={styles.divider} />
        </>
      )}
      <ResponsiveBar
        animate
        data={data}
        defs={defs}
        sortByValue
        padding={0.3}
        axisTop={null}
        padAngle={0.7}
        indexBy="index"
        borderWidth={1}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -50,
            itemsSpacing: 20,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        cornerRadius={3}
        axisRight={null}
        innerRadius={0.5}
        motionDamping={15}
        keys={barChartKeys}
        labelSkipWidth={12}
        labelSkipHeight={12}
        motionStiffness={90}
        radialLabelsSkipAngle={8}
        radialLabelsLinkOffset={0}
        slicesLabelsSkipAngle={10}
        radialLabelsTextXOffset={4}
        colors={{ scheme: 'nivo' }}
        radialLabelsTextColor="#333333"
        slicesLabelsTextColor="#333333"
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkDiagonalLength={14}
        radialLabelsLinkHorizontalLength={20}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40
        }}
        radialLabelsLinkColor={{ from: 'color' }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 32
        }}
        margin={{ top: 60, right: 10, bottom: 30, left: 30 }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        fill={data.map(({ id }) => ({ match: { id }, id: sample(['dots', 'lines'])}))}
      />
    </div>
  );
};

PageBarChart.propTypes = {
  fields: PropTypes.array,
  renderMini: PropTypes.bool,
};

PageBarChart.defaultProps = {
  fields: [],
  renderMini: false,
};

export default PageBarChart;

import React from 'react';
import { List } from 'antd';
import get from 'lodash/get';
import { Link } from 'gatsby';
import keys from 'lodash/keys';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import styles from './index.module.scss';

const CategoriesSidebar = ({ nodesByCategory, categoriesOrdered, categoryPrefix }) => (
  <List
    className={styles.list}
    itemLayout="horizontal"
    dataSource={categoriesOrdered || keys(nodesByCategory).sort()}
    renderItem={category => (
      <div key={category} className={styles.listContainer}>
        <List.Item className={styles.listItemHeader}>
          <List.Item.Meta title={`${categoryPrefix || ''} ${startCase(category)}`} />
        </List.Item>
        <>
          {nodesByCategory[category].map((node, i) => (
            <Tooltip
              placement="left"
              key={get(node, '_meta.uid')}
              title={get(node, 'description[0].text')}
            >
              <div className={styles.listLink}>
                <Link
                  key={get(node, '_meta.uid')}
                  to={`/${category}/${node._meta.uid}`}
                >
                  <img
                    data-src={get(node, 'top_image.url')}
                    alt={get(node, 'top_image.alt', 'Image Cover')}
                    className={`${styles.listImage} list-image-${i}`}
                  />
                  <div>{get(node, 'title[0].text')}</div>
                </Link>
              </div>
            </Tooltip>
          ))}
        </>
      </div>
    )}
  />
);

CategoriesSidebar.propTypes = {
  nodesByCategory: PropTypes.object,
  categoriesOrdered: PropTypes.array,
  categoryPrefix: PropTypes.string,
};

CategoriesSidebar.defaultProps = {
  nodesByCategory: {},
  categoriesOrdered: null,
  categoryPrefix: null,
};

export default CategoriesSidebar;

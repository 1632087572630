import React from 'react';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import kebabCase from 'lodash/kebabCase';
import { graphql, useStaticQuery, Link } from 'gatsby';

import TAGS from '../../data/tags';
import CATEGORIES from '../../data/categories';

import styles from './index.module.scss';

const datayearsDescription = `Datayears creates in-depth articles, statistics & information on various categories. Our goal is to provide the best, most relevant and informative data to help our users extend their knowledge and understanding, and direct them to the best resources online.`;

const LogoQuery = graphql`
    {
        logo: file(relativePath: { eq: "datayears-logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    originalImg
                }
            }
        }
    }
`;

const placeholder = '⌛ datayears - life is eventually years of data ⌛';

const Footer = () => {
    const data = useStaticQuery(LogoQuery);
    const logoSrc = get(data, 'logo.childImageSharp.fluid.originalImg', null);

    const chunkedCategories = chunk([...CATEGORIES].sort(), 7);
    const chunkedTags = chunk(TAGS.map(lowerCase).sort(), 11);

    return (
        <footer className={styles.footer}>
            <section className={`page ${styles.footerSection}`}>
                <div className={styles.footerSectionLeft}>
                    <a href="/" title={placeholder}>
                        <img 
                            src={logoSrc} 
                            alt={placeholder} 
                            className={styles.footerSectionLeftLogo}
                        />
                    </a>
                    <h5 className={styles.footerSectionLeftText}>{datayearsDescription}</h5>
                </div>
                <div className={styles.footerSectionRight}>
                    <div className={styles.footerSectionRightMain}>
                        <h5>
                            <Link to="/categories">Categories</Link>
                        </h5>
                        <div className={styles.footerSectionRightContainer}>
                            {chunkedCategories.map((categoriesList, i) => (
                                <div key={`category-${i}`} className={styles.footerSectionRightContainerLinks}>
                                    {categoriesList.map(category => (
                                        <Link to={`/${category}`} key={category}>
                                            {startCase(category)}
                                        </Link>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.footerSectionRightMain}>
                        <h5>
                            <Link to="/tags">Tags</Link>
                        </h5>
                        <div className={styles.footerSectionRightContainer}>
                            {chunkedTags.map((tagsList, i) => (
                                <div key={`tags-${i}`} className={styles.footerSectionRightContainerLinks}>
                                    {tagsList.map(tag => (
                                        <Link to={`/tags/${kebabCase(tag)}`} key={tag}>
                                            {startCase(tag)}
                                        </Link>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles.copyright}>
                Copyright © 2019-{new Date().getFullYear()} Datayears All Rights Reserved
            </div>
        </footer>
    );
};

export default Footer;

module.exports = [
  'automobile',
  'business',
  // 'commodities',
  // 'demography',
  // 'fashion',
  'finance',
  'food',
  // 'games',
  // 'health',
  'movies',
  // 'music',
  'nature',
  'technology',
  'travel',
];

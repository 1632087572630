import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { FcCalendar } from 'react-icons/fc';

import CategoryTag from '../../category-tag';
import { formatDate } from '../../../utils/date-utils';

import styles from '../index.module.scss';

const MetaTitle = ({ node }) => {
    const date = get(node, 'page_date', '');
    const tags = get(node, '_meta.tags', []);
    const category = get(node, 'category', '');
    const title = get(node, 'title[0].text', '');
    return (
        <div>
            <div className={styles.cardTitleTop}>
                <Typography.Text type="secondary" className={styles.cardDate}>
                    <FcCalendar size={18} /> &nbsp; {formatDate(date)}
                </Typography.Text>
                <Link to={`/${category}`} className={styles.categoryLink}>
                    <CategoryTag category={category} className={styles.cardTitleTag} />
                </Link>
            </div>
            {!isEmpty(tags) && (
                <div className={styles.cardTitleTags}>
                {tags.map(tag => (
                    <Link 
                        key={tag} 
                        to={`/tags/${kebabCase(tag)}`} 
                        className={styles.cardTitleTagsLink}
                    >
                        <em>#{startCase(tag)}</em>
                    </Link>
                ))}
                </div>
            )}
            <h4 className={styles.cardTitle}>{title}</h4>
        </div>
    );
};

MetaTitle.propTypes = {
    node: PropTypes.object,
};

export default MetaTitle;
import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { FaMoneyBillAlt, FaCar, FaCode, FaGamepad } from 'react-icons/fa';
import { GiShirt, GiTreeBranch, GiWalk, GiHamburger, GiGoldBar } from 'react-icons/gi';
import { MdPeople, MdLocalMovies, MdQueueMusic, MdBusinessCenter } from 'react-icons/md';

import CATEGORIES from '../../data/categories';

import styles from './index.module.scss';

const categoryTagComponents = {
  music: {
    Icon: <MdQueueMusic size={16} />,
    color: 'lime',
  },
  business: {
    Icon: <MdBusinessCenter size={16} />,
    color: 'black',
  },
  technology: {
    Icon: <FaCode size={16} />,
    color: '#059ee6',
  },
  finance: {
    Icon: <FaMoneyBillAlt size={16} />,
    color: 'green',
  },
  // stocks: {
  //   Icon: <GrMoney size={16} />,
  //   color: '#dab403',
  //   textColor: 'black',
  // },
  commodities: {
    Icon: <GiGoldBar size={16} />,
    color: 'gold',
  },
  food: {
    Icon: <GiHamburger size={16} />,
    color: 'orange',
  },
  movies: {
    Icon: <MdLocalMovies size={16} />,
    color: 'volcano',
  },
  games: {
    Icon: <FaGamepad size={16} />,
    color: '#2db7f5',
  },
  // software: {
  //   Icon: <FaCode size={16} />,
  //   color: 'geekblue',
  // },
  automobile: {
    Icon: <FaCar size={16} />,
    color: 'magenta',
  },
  television: {
    Icon: <AiOutlineFundProjectionScreen size={16} />,
    color: 'pink',
  },
  demography: {
    Icon: <MdPeople size={16} />,
    color: 'purple',
  },
  nature: {
    Icon: <GiTreeBranch size={16} />,
    color: '#87d068',
  },
  travel: {
    Icon: <GiWalk size={16} />,
    color: 'cyan',
  },
  fashion: {
    Icon: <GiShirt size={16} />,
    color: 'red',
  },
};

const CategoryTag = ({ category, ...rest }) => (
  <Tag
    icon={categoryTagComponents[category].Icon}
    color={categoryTagComponents[category].color}
    style={
      categoryTagComponents[category].textColor ? { 
        color: categoryTagComponents[category].textColor,
      } : {}
    }
    {...rest}
  >
    <span className={styles.text}>{startCase(category)}</span>
  </Tag>
);

CategoryTag.propTypes = {
  category: PropTypes.oneOf(CATEGORIES),
};

export default CategoryTag;

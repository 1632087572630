import { getLeftTopFromCenter } from './window-utils';

export const share = (url = '') => (urlParams = '') => {
  const { left, top } = getLeftTopFromCenter(650, 600);
  window.open(`${url}${urlParams ? '?' : ''}${urlParams}`, '_blank', `left=${left},top=${top},width=650,height=600`);
};

export const shareFacebook = urlParams =>
  share('https://www.facebook.com/sharer.php')(urlParams);

export const shareTwitter = urlParams =>
  share('https://twitter.com/intent/tweet')(urlParams);

export const shareTumblr = urlParams =>
  share('http://www.tumblr.com/share/link')(urlParams);

export const shareLinkedin = urlParams =>
  share('https://www.linkedin.com/shareArticle')(urlParams);

export const sharePinterest = urlParams =>
  share('http://pinterest.com/pin/create/button/')(urlParams);

export const shareWhatsapp = urlParams =>
  share('https://wa.me')(urlParams);

export const shareTelegram = urlParams =>
  share('https://telegram.me/share/url')(urlParams);

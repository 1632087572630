import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import kebabCase from 'lodash/kebabCase';

import styles from './index.module.scss';

const TagsSidebar = ({ title, tags }) => {
    if (isEmpty(tags)) {
        return null;
    }
    return (
        <aside className={styles.aside}>
            <h4 className={styles.title}>{title}</h4>
            <Divider className={styles.divider} />
            <div className={styles.tags}>
                {tags.map(lowerCase).sort().map(tag => (
                    <Link 
                        key={tag} 
                        to={`/tags/${kebabCase(tag)}`} 
                        className={styles.tagsLink}
                    >
                        #{startCase(tag)}
                    </Link>
                ))}
            </div>
        </aside>
    );
};

TagsSidebar.propTypes = {
    title: PropTypes.string,
    tags: PropTypes.array,
};

TagsSidebar.defaultProps = {
    title: 'Related Tags',
    tags: [],
};

export default TagsSidebar;

import { Tabs } from 'antd';
import get from 'lodash/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { FcCalendar } from 'react-icons/fc';
import { MdMoreVert } from 'react-icons/md';
import { FaShareAlt } from 'react-icons/fa';
import { AiOutlineTable } from 'react-icons/ai';
import React, { useState, useEffect } from 'react';
import { FiPieChart, FiBarChart } from 'react-icons/fi';
import { Card, Typography, Tooltip, Popover } from 'antd';
import { BsTextIndentLeft, BsFileEarmarkText } from 'react-icons/bs';

import Cover from './components/cover';
import CategoryTag from '../category-tag';
import MetaTitle from './components/meta-title';
import SocialShare from './components/social-share';
import { formatDate } from '../../utils/date-utils';
import MetaDescription from './components/meta-description';
import { findPageLabel, getReachText  } from '../../utils/reach-text-utils';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const isDataSocialShare = e => (
    e && e.target 
    && (
      !e.target.hasAttribute('data-social-share') 
      || (e.target.parentElement && !e.target.parentElement.hasAttribute('data-social-share'))
      || (e.target.parentElement.parentElement && !e.target.parentElement.parentElement.hasAttribute('data-social-share'))
    )
);

const getTabList = node => {
  const uid = get(node, '_meta.uid', '');

  const reachTextInput = getReachText(get(node, 'body', []));
  const tldr = findPageLabel(reachTextInput, 'page-tldr');
  const table = findPageLabel(reachTextInput, 'page-table');
  const pieChart = findPageLabel(reachTextInput, 'page-pie-chart');
  const barChart = findPageLabel(reachTextInput, 'page-bar-chart');
  const allTabsEmpty = !tldr && !table && !pieChart && !barChart;

  if (allTabsEmpty) {
    return [{ key: 'empty', tab: <div style={{ marginBottom: 15 }} /> }];
  }

  const list = [{
    key: 'meta',
    tab: (
      <Tooltip
        placement="top"
        key={`${uid}-meta-tooltip`}
        title="Basic Info"
      >
        <BsFileEarmarkText key={`${uid}-meta`} size={18} />
      </Tooltip>
    ),
  }];
  if (tldr) {
    list.push({
      key: 'tldr',
      tab: (
        <Tooltip
          placement="top"
          key={`${uid}-tldr-tooltip`}
          title="Too long didn't read - shorten info"
        >
          <BsTextIndentLeft key={`${uid}-tldr`} size={18} />
        </Tooltip>
      ),
    });
  }
  if (table) {
    list.push({
      key: 'table',
      tab: (
        <Tooltip
          placement="top"
          key={`${uid}-table-tooltip`}
          title="Tabular Info"
        >
          <AiOutlineTable key={`${uid}-table`} size={18} />
        </Tooltip>
      ),
    });
  }
  if (pieChart) {
    list.push({
      key: 'pie-chart',
      tab: (
        <Tooltip
          placement="top"
          key={`${uid}-pie-chart-tooltip`}
          title="Comparison Pie Chart"
        >
          <FiPieChart key={`${uid}-pie-chart`} size={17} />
        </Tooltip>
      ),
    });
  }
  if (barChart) {
    list.push({
      key: 'bar-chart',
      tab: (
        <Tooltip
          placement="top"
          key={`${uid}-bar-chart-tooltip`}
          title="Comparison Bar Chart"
        >
          <FiBarChart key={`${uid}-bar-chart`} size={18} />
        </Tooltip>
      ),
    });
  }
  return list;
};

const MobileInfoCard = ({ node, expanded }) => {
  const { windowWidth } = useSelector(({ global }) => global);

  const [currentTab, setTab] = useState('meta');
  const [changedTab, setChangedTab] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const uid = get(node, '_meta.uid', []);
  const date = get(node, 'page_date', '');
  const tags = get(node, '_meta.tags', []);
  const category = get(node, 'category', '');
  const title = get(node, 'title[0].text', '');
  const description = get(node, 'description[0].text', '');
  const { url, alt = '' } = get(node, 'top_image_mobile', {});

  const tabList = (() => {
    const list = getTabList(node);
    if (list[0].key === 'empty') return [];
    return list;
  })();

  useEffect(() => {
    if (currentTab !== 'meta') {
      setChangedTab(true);
    }
  }, [currentTab]);

  return (
    <Link 
      to={`/${category}/${uid}`} 
      className={styles.mobileCard}
      onClick={e => {
        if (popoverVisible) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={`${styles.cardTitleTop} ${styles.mobileCardTitle}`}>
        <Typography.Text type="secondary" className={styles.cardDate}>
          <FcCalendar size={18} /> &nbsp; {formatDate(date)}
        </Typography.Text>
        <Link to={`/${category}`} className={styles.categoryLink}>
          <CategoryTag category={category} className={styles.cardTitleTag} />
        </Link>
      </div>
      <div className={!expanded ? styles.mobileCardCenter : ''}>
        <h2>{title}</h2>
        <div className={!expanded ? styles.mobileCardCenterImage : ''}>
          <img alt={alt} data-src={url} style={expanded ? {minWidth: windowWidth - 50} : {}} />
        </div>
      </div>
      <div className={styles.mobileCardBottom}>
        <Popover 
          trigger="click"
          placement="topRight" 
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          title={
            isEmpty(tabList) ? (
              <div className={styles.mobileCardDescription}>
                {description}
              </div>
            ) : (
              <div className={styles.mobileCardTabs}>
                <Tabs activeKey={currentTab} onChange={setTab}>
                  {tabList.map(tab => (
                      <Tabs.TabPane key={tab.key} tab={tab.tab}>
                        <Cover 
                          currentTab={currentTab} 
                          changedTab={changedTab} 
                          node={node}
                          CustomMeta={() => 
                            <div className={styles.mobileCardDescription}>
                              {description}
                            </div>
                          }
                        />
                      </Tabs.TabPane>
                  ))}
                </Tabs>
              </div>
            )
          } 
          content={
            <a 
              onClick={e => {
                e.stopPropagation();
                setPopoverVisible(false);
              }}
            >
              Close
            </a>
          }
          visible={popoverVisible}
          onVisibleChange={visible => setPopoverVisible(visible)}
        >
          <MdMoreVert size={20} />
        </Popover>
        {!isEmpty(tags) && (
          <div className={styles.mobileCardTitleTags}>
            {tags.map(tag => (
              <Link 
                key={tag} 
                to={`/tags/${kebabCase(tag)}`}
                className={styles.cardTitleTagsLink}
              >
                <em>#{startCase(tag)}</em>
              </Link>
            ))}
          </div>
        )}
      </div>
    </Link>
  );
};

const InfoCard = ({ node, renderSocialShare, renderTabs, renderCover, minimal, expanded }) => {
  const [currentTab, setTab] = useState('meta');
  const [changedTab, setChangedTab] = useState(false);
  const [showSocial, setShowSocial] = useState(false);

  useEffect(function onDocClick() {
    function onClick(e) {
      if (isDataSocialShare(e) && showSocial) {
        setShowSocial(false);
      }
    }
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  });

  useEffect(() => {
    if (currentTab !== 'meta') {
      setChangedTab(true);
    }
  }, [currentTab]);

  if (minimal) {
    return <MobileInfoCard node={node} expanded={expanded} />;
  }
  return (
    <Card
      bordered
      className={styles.card}
      cover={renderCover && (
        <Cover currentTab={currentTab} changedTab={changedTab} node={node} />
      )}
      tabList={renderTabs && getTabList(node)}
      tabBarExtraContent={renderSocialShare && (
        <div 
          data-social-share
          className={`${styles.socialContainer} ${showSocial ? styles.socialContainerVisible : ''}`}
        >
          <FaShareAlt
            size={15} 
            data-social-share
            onClick={() => setShowSocial(true)}
          />
          <SocialShare 
            node={node}
            data-social-share
            visible={showSocial}
          />
        </div>
      )}
      onTabChange={renderTabs ? setTab : () => {}}
    >
      <Card.Meta
        title={<MetaTitle node={node} />}
        description={<MetaDescription node={node} />}
      />
    </Card>
  );
};

InfoCard.propTypes = {
  node: PropTypes.object,
  minimal: PropTypes.bool,
  expanded: PropTypes.bool,
  renderTabs: PropTypes.bool,
  renderCover: PropTypes.bool,
  renderSocialShare: PropTypes.bool,
};

InfoCard.defaultProps = {
  node: {},
  minimal: false,
  expanded: true,
  renderTabs: true,
  renderCover: true,
  renderSocialShare: true,
};

export default InfoCard;

import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import styles from './layout.module.scss';

const loadLazyElement = lazyElement => {
  if (lazyElement.dataset.src) {
    lazyElement.src = lazyElement.dataset.src;
    if (lazyElement.dataset.srcset) {
      lazyElement.srcset = lazyElement.dataset.srcset;
    }
    lazyElement.removeAttribute('data-src');
  }
};

const useLazyLoad = (options = { rootMargin: '150px' }) => {
  useEffect(function onLazyImages() {
    let lazyLoadPolyfill;

    function lazyLoad() {
      let lazyElements = [].slice.call(document.querySelectorAll('img[data-src], iframe[data-src]'));

      if ('IntersectionObserver' in window) {
        const lazyElementObserver = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const lazyElement = entry.target;
              loadLazyElement(lazyElement);
              lazyElementObserver.unobserve(lazyElement);
            }
          });
        }, options);

        lazyElements.forEach(lazyElement => 
          lazyElementObserver.observe(lazyElement)
        );
      } else {
        let active = false;

        lazyLoadPolyfill = () => {
          if (active === false) {
            active = true;

            window.setTimeout(() => {
              lazyElements.forEach(lazyElement => {
                if (
                  lazyElement.getBoundingClientRect().top <= window.innerHeight
                  && lazyElement.getBoundingClientRect().bottom >= 0
                  && getComputedStyle(lazyElement).display !== 'none'
                ) {
                  loadLazyElement(lazyElement);

                  lazyElements = lazyElements.filter(el => el !== lazyElement);

                  if (lazyElements.length === 0) {
                    document.removeEventListener('scroll', lazyLoadPolyfill);
                    window.removeEventListener('resize', lazyLoadPolyfill);
                    window.removeEventListener('orientationchange', lazyLoadPolyfill);
                  }
                }
              });

              active = false;
            }, 200);
          }
        };

        document.addEventListener('scroll', lazyLoadPolyfill);
        window.addEventListener('resize', lazyLoadPolyfill);
        window.addEventListener('orientationchange', lazyLoadPolyfill);
      }
    }

    lazyLoad();

    return () => {
      if (lazyLoadPolyfill) {
        document.removeEventListener('scroll', lazyLoadPolyfill);
        window.removeEventListener('resize', lazyLoadPolyfill);
        window.removeEventListener('orientationchange', lazyLoadPolyfill);
      }
    };
  });
};

const useWindowWidth = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth || 1200;
      dispatch({ type: 'SET_WINDOW_WIDTH', payload: { width }});
    };
    onResize();
    const onResizeDebounced = debounce(onResize, 100);
    window.addEventListener('resize', onResizeDebounced);
    return () => {
      window.removeEventListener('resize', onResizeDebounced);
    };
  }, [dispatch]);
};

const TemplateLayout = ({ children }) => {
  useLazyLoad();
  useWindowWidth();

  return (
    <div>
      <Navbar />
      <div className={`page ${styles.layout}`}>
        {children}      
      </div>
      <Footer />
    </div>
  );
};

export default TemplateLayout;

import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import PageListItem from './components/page-list-item';

const PageList = ({ fields }) => {
  if (isEmpty(fields)) {
    return null;
  }

  return (
    <div className="page-list">
      {fields.map((field, i) => (
        <PageListItem
          key={`reach_text-${i}`}
          fields={get(field, 'reach_text', [])}
        />
      ))}
    </div>
  );
};

PageList.propTypes = {
  fields: PropTypes.array,
};

PageList.defaultProps = {
  fields: [],
};

export default PageList;

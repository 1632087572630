import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ReactHtmlParser from 'react-html-parser';

import CATEGORIES from '../../data/categories';

const createURL = span => {
  if (span.data.link_type === 'Document') {
    if (typeof window !== `undefined`) {
      const locationPathname = window.location.pathname.split('/').slice(1);
      if (locationPathname.length === 2 && CATEGORIES.includes(locationPathname[0])) {
        return `/${locationPathname[0]}/${span.data.uid}`;
      }
    }
    return `/${span.data.uid}`;
  }
  if (span.data.link_type === 'Web' && span.data.url.startsWith('https://tags')) {
    return `/${span.data.url.replace('https://', '')}`;
  }
  return span.data.url;
};

const createElementFromSpanType = (span, text) => {
  if (span.type === 'hyperlink' && span.data) {
    const policies = span.data.target === '_blank' ? ' rel="noopener"' : '';
    return `<a${policies} href="${createURL(span)}" target="${span.data.target || '_self'}">${text}</a>`;
  }
  return `<${span.type}>${text}</${span.type}>`;
};

const getSpanElementSize = span => {
  if (span.type === 'hyperlink' && span.data) {
    const policies = span.data.target === '_blank' ? ' rel="noopener"' : '';
    return `<a href="${createURL(span)}" target=""></a>`.length + (span.data.target || '_self').length + policies.length;
  }
  return span.type.length * 2 + 5; // <strong><strong/>, <em></em>;
};

const reachTextToHTML = input => {
  if (isEmpty(input.spans)) {
    return input.text;
  }
  let text = input.text, typesSize = 0;
  input.spans.forEach(span => {
    const start = span.start + typesSize;
    const end = span.end + typesSize;
    text = (
      text.slice(0, start) +
      createElementFromSpanType(span, text.slice(start, end)) +
      text.slice(end)
    );
    typesSize += getSpanElementSize(span);
  });
  return text;
};

const ReachTextResolver = ({ input, attributes, typeAttributes }) => {
  if (isEmpty(input)) {
    return null;
  }

  switch (input.type) {
    case 'paragraph':
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'heading4':
    case 'heading5':
    case 'heading6': {
      const el = input.type.replace('eading', '').replace('aragraph', '');
      return React.createElement(
        el,
        { ...attributes, ...(typeAttributes[input.type] || {}) },
        ReactHtmlParser(reachTextToHTML(input))
      );
    }
    case 'preformatted': {
      return (
        <pre
          className="pre-hint"
          {...attributes}
          {...(typeAttributes[input.type] || {})}
        >
          {ReactHtmlParser(reachTextToHTML(input))}
        </pre>
      );
    }
    case 'image':
      return (
        <img
          data-src={input.url}
          alt={input.alt || ''}
          title={input.alt || ''}
          {...attributes}
          {...(typeAttributes[input.type] || {})}
        />
      );
    case 'embed':
      return (
        <div
          className="datayears-embed"
          dangerouslySetInnerHTML={{
            __html: input.oembed.html.replace('src', 'data-src'),
          }}
          {...attributes}
          {...(typeAttributes[input.type] || {})}
        />
      );
    default:
      return null;
  }
};

ReachTextResolver.propTypes = {
  input: PropTypes.object,
  attributes: PropTypes.object,
  typeAttributes: PropTypes.object,
};

ReachTextResolver.defaultProps = {
  input: {},
  attributes: {},
  typeAttributes: {},
};

export default ReachTextResolver;

import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ReachTextResolver from '../../../reach-text-resolver';

import styles from './index.module.scss';

const PageIntro = ({ fields }) => {
  if (isEmpty(fields)) {
    return null;
  }

  return (
    <div className={`page-intro ${styles.pageIntro}`}>
      <span className={styles.pageIntroRibbon}>Intro</span>
      {fields.map((field, i) => (
        <React.Fragment key={`page-intro-${i}`}>
          {get(field, 'reach_text', []).map((input, j) => (
            <ReachTextResolver
              key={`page-intro-inner-${j}`}
              input={input}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

PageIntro.propTypes = {
  fields: PropTypes.array,
};

PageIntro.defaultProps = {
  fields: [],
};

export default PageIntro;

import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import sample from 'lodash/sample';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import React, { useState, useEffect } from 'react';

import styles from './index.module.scss';

const defs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    size: 4,
    padding: 1,
    stagger: true,
  },
  {
    id: 'lines',
    type: 'patternLines',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    rotation: -45,
    lineWidth: 6,
    spacing: 10,
  },
];

const PagePieChart = ({ fields, renderMini }) => {
  const [chartStyle, setChartStyle] = useState({});
  const { windowWidth } = useSelector(({ global }) => global);
  const isMobile = windowWidth < 630;

  useEffect(function setChartHeightIfNotSet() {
    setChartStyle({ 
      height: renderMini || isMobile ? 280 : 420,
      maxWidth: renderMini ? '92vw' : 'inherit',
     });
  }, [isMobile, renderMini]);

  if (isEmpty(fields)) {
    return null;
  }

  const textNodes = get(fields, '[0].reach_text', []);
  const heading = (find(textNodes, ({ type }) => type.includes('heading')) || {}).text;
  const chartRawData = textNodes.filter(({ type }) => type === 'paragraph');

  const pieChartData = compact(map(chartRawData, 'text'));
  if (isEmpty(pieChartData) || pieChartData.length === 1) {
    return null;
  }

  const data = compact(
    pieChartData.map(dataString => {
      const dataArray = compact(dataString.split(',').map(str => trim(str)));
      if (dataArray.length !== 2) return null;
      const [label, value] = dataArray;
      return { id: label, label, value };
    })
  );

  const headingStyle = renderMini ? {fontSize: 18} : {};

  return (
    <div
      className={`page-pie-chart ${styles.container}`}
      style={chartStyle}
    >
      {heading && (
        <>
          <h3 className={styles.heading} style={headingStyle}>{heading}</h3>
          <hr className={styles.divider} />
        </>
      )}
      <ResponsivePie
        animate
        data={data}
        defs={defs}
        sortByValue
        padAngle={0.7}
        borderWidth={1}
        cornerRadius={3}
        innerRadius={0.5}
        motionDamping={15}
        motionStiffness={90}
        radialLabelsSkipAngle={8}
        radialLabelsLinkOffset={0}
        slicesLabelsSkipAngle={10}
        radialLabelsTextXOffset={1}
        colors={{ scheme: 'paired' }}
        radialLabelsTextColor="#333333"
        slicesLabelsTextColor="#333333"
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkDiagonalLength={10}
        radialLabelsLinkHorizontalLength={16}
        radialLabelsLinkColor={{ from: 'color' }}
        margin={{ top: 40, right: 80, bottom: 15, left: 80 }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        fill={data.map(({ id }) => ({ match: { id }, id: sample(['dots', 'lines'])}))}
      />
    </div>
  );
};

PagePieChart.propTypes = {
  fields: PropTypes.array,
  renderMini: PropTypes.bool,
};

PagePieChart.defaultProps = {
  fields: [],
  renderMini: false,
};

export default PagePieChart;

import React from 'react';
import get from 'lodash/get';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ReachTextResolver from '../../../reach-text-resolver';

import styles from './index.module.scss';

const TLDR_MEANING = `TL;DR is an acronym for "Too Long; Didn't Read". This section holds the main takeaways from the article.`;

const PageTldr = ({ fields, renderMini }) => {
  if (isEmpty(fields)) {
    return null;
  }

  const pageTldrStyle = renderMini ? { marginBottom: 0 } : {};
  const preformattedStyle = renderMini ? {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    marginLeft: 10,
    overflow: 'hidden',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  } : {
    paddingTop: 5,
    marginLeft: 25,
    paddingLeft: 25,
    paddingBottom: 5,
  };

  return (
    <div className={`page-tldr ${styles.pageTldr}`} style={pageTldrStyle}>
      <Tooltip placement="top" title={TLDR_MEANING}>
        <span className={styles.pageTldrRibbon}>TL;DR</span>
      </Tooltip>
      {fields.map((field, i) => (
        <React.Fragment key={`page-tldr-${i}`}>
          {get(field, 'reach_text', []).map((input, j) => (
            <ReachTextResolver
              key={`page-tldr-inner-${j}`}
              input={input}
              typeAttributes={{
                preformatted: { style: preformattedStyle },
              }}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

PageTldr.propTypes = {
  fields: PropTypes.array,
  renderMini: PropTypes.bool,
};

PageTldr.defaultProps = {
  fields: [],
  renderMini: false,
};

export default PageTldr;

import { Tag } from 'antd';
import get from 'lodash/get';
import some from 'lodash/some';
import keys from 'lodash/keys';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';

import TAGS from '../../data/tags';
import SEO from '../../components/seo';
import TemplateLayout from '../layout';
import InfoCard from '../../components/info-card';
import TagsSidebar from '../../components/tags-sidebar';
import CategoriesSidebar from '../../components/categories-sidebar';

import styles from './index.module.scss';

const CategoryPosts = ({ category, isMobile, children }) => {
  if (isMobile) {
    return (
      <div className={styles.cardsCategory}>
        <h2>{startCase(category)}</h2>
        {children}
      </div>
    );
  }
  return <>{children}</>;
};

const HomePage = ({ pageContext }) => {
  const { windowWidth } = useSelector(({ global }) => global);
  const isMobile = windowWidth < 630;

  const nodesByCategory = get(pageContext, 'data', {});
  const initialOrderedCategories = keys(nodesByCategory).sort();
  const [categories, setCategories] = useState(initialOrderedCategories);

  // { music: true, stocks: false, ... }
  const [categoryCheck, setCategoryCheck] = useState({});
  const allCategoriesUnchecked = isEmpty(categoryCheck) || !some(categoryCheck);

  const onCheckToggle = (category, checked) => {
    setCategoryCheck({ ...categoryCheck, [category]: checked });
    if (checked) {
      reorderCategoriesByLastCheckedCategory(category);
    }
    window.scrollTo(0, 0);
  };

  const reorderCategoriesByLastCheckedCategory = lastCheckedCategory => {
    const _categories = categories.filter(category => category !== lastCheckedCategory);
    _categories.unshift(lastCheckedCategory);
    setCategories(_categories);
  };

  return (
    <TemplateLayout>
      <SEO />

      <div className={styles.categoryFilters}>
        <div className={`page ${styles.categoryFiltersInner}`}>
          {initialOrderedCategories.map(category => (
            <Tag.CheckableTag
              key={category}
              checked={categoryCheck[category]}
              onChange={checked => onCheckToggle(category, checked)}
              className={`${styles.categoryFiltersTag} ${categoryCheck[category] ? styles.categoryFiltersTagChecked : ''}`}
            >
              {startCase(category)}
            </Tag.CheckableTag>
          ))}
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.cards}>
          {categories.map(category =>
            (allCategoriesUnchecked || categoryCheck[category]) && (
              <CategoryPosts category={category} isMobile={isMobile}>
                <>
                  {nodesByCategory[category].map((node, i) => (
                    <InfoCard
                      key={get(node, '_meta.uid')} 
                      node={node} 
                      minimal={isMobile}
                      expanded={i === 0}
                    />
                  ))}
                </>
              </CategoryPosts>
            ))
          }
        </div>

        {!isMobile && (
          <div className={styles.sidebarContainer}>
            <CategoriesSidebar
              nodesByCategory={nodesByCategory}
              categoriesOrdered={categories}
              categoryPrefix="More on"
            />
            <TagsSidebar title="Tags" tags={TAGS} />
          </div>
        )}

        <div className={styles.clear} />
      </div>
    </TemplateLayout>
  );
};

HomePage.propTypes = {
  pageContext: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }).isRequired,
};

export default HomePage;

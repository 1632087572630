import get from 'lodash/get';
import map from 'lodash/map';
import gql from 'graphql-tag';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import { FaSearch } from 'react-icons/fa';
import ReactHtmlParser from 'react-html-parser';
import React, { useState, useEffect, useRef } from 'react';
import { SyncOutlined, MailOutlined } from '@ant-design/icons';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { Divider, List, Select, Tooltip, Spin, Button } from 'antd';

import CATEGORIES from '../../data/categories';
import getClient from '../../gql/browser-client';
import { getLeftTopFromCenter } from '../../utils/window-utils';

import styles from './navbar.module.scss';

const placeholder = '⌛ datayears - life is eventually years of data ⌛';

const LogoQuery = graphql`
    {
        logo: file(relativePath: { eq: "datayears-logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    originalImg
                }
            }
        }
    }
`;

const SearchQuery = gql`
    query SearchQuery($searchTerm: String!) {
        allPages(lang: "en-us", fulltext: $searchTerm) {
            totalCount
            edges {
                node {
                    category
                    year
                    title
                    description
                    _meta {
                        uid
                    }
                }
            }
        }
    }
`;

const emphasize = (textNode, searchTerm) => {
  const text = textNode[0].text.replace(/<strong\/?>/g, '');
  return text.replace(
    new RegExp(searchTerm, 'ig'),
    val => `<strong>${val}</strong>`
  );
};

const isDataSearch = el =>
  el && (
    el.hasAttribute('data-search')
    || (el.parentElement && el.parentElement.hasAttribute('data-search'))
  );

const Navbar = () => {
  const data = useStaticQuery(LogoQuery);
  const logoSrc = get(data, 'logo.childImageSharp.fluid.originalImg', null);

  const searchInputEl = useRef(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [hoveredSearchOption, setHoveredSearchOption] = useState(null);

  const clearSearchOptions = () => {
    if (!isEmpty(searchOptions)) {
      setSearchOptions([]);
    }
  };

  const clearSearchInput = () => {
    if (searchInputEl.current.value) {
      searchInputEl.current.value = '';
    }
  };

  const clearHoveredSearchOptions = () => {
    if (!isNil(hoveredSearchOption)) {
      setHoveredSearchOption(null);
    }
  };

  const handleSearch = async term => {
    const searchTerm = trim(term);
    if (!isEmpty(searchTerm)) {
      try {
        setIsSearchLoading(true);
        const client = await getClient();
        const { data, errors } = await client.query({
          query: SearchQuery,
          variables: { searchTerm },
        });
        if (errors) {
          throw new Error(errors);
        }
        const searchResults = map(get(data, 'allPages.edges', []), 'node');
        setSearchOptions(searchResults.map(
          ({ title, category, description, year, _meta }) => ({
            linkTo: `/${category}/${_meta.uid}`,
            text: ReactHtmlParser(`${emphasize(title, searchTerm)}${get(title, '[0].text', '').includes(year.toString()) ? '' : ` - ${year}`}`),
            description: ReactHtmlParser(emphasize(description, searchTerm)),
          }))
        );
      } catch (e) {
        console.warn(e);
      }
      setIsSearchLoading(false);
      searchInputEl.current.focus();
    }
  };

  const onSearch = debounce(handleSearch, 1000);

  // set timeout in case a link is clicked
  const onBlur = () => {
    window.setTimeout(() => {
      clearSearchOptions();
      clearHoveredSearchOptions();
    }, 200);
  };

  const onFocus = async () => {
    const searchTerm = trim(searchInputEl.current.value);
    if (searchTerm && isEmpty(searchOptions)) {
      await handleSearch(searchTerm);
    }
  };

  const onKeyDown = async e => {
    if (!isSearchLoading) {
      switch (e.key) {
        case 'Escape':
          clearSearchInput();
          clearSearchOptions();
          clearHoveredSearchOptions();
          break;
        case 'Backspace':
          if (searchInputEl.current.value.length === 0) {
            clearSearchOptions();
            clearHoveredSearchOptions();
          }
          break;
        case 'ArrowDown':
          if (!isEmpty(searchOptions)) {
            if (isNil(hoveredSearchOption)) {
              setHoveredSearchOption(0);
            } else if (hoveredSearchOption === searchOptions.length - 1) {
              setHoveredSearchOption(null);
            } else {
              setHoveredSearchOption(hoveredSearchOption + 1);
            }
          }
          break;
        case 'ArrowUp':
          if (!isEmpty(searchOptions)) {
            if (isNil(hoveredSearchOption)) {
              setHoveredSearchOption(searchOptions.length - 1);
            } else if (hoveredSearchOption === 0) {
              setHoveredSearchOption(null);
            } else {
              setHoveredSearchOption(hoveredSearchOption - 1);
            }
          }
          break;
        case 'Enter':
          if (!isNil(hoveredSearchOption) && !isEmpty(searchOptions)) {
            await navigate(searchOptions[hoveredSearchOption].linkTo);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(function onDocClick() {
    function onClick(e) {
      if (!isDataSearch(e.target) && !isEmpty(setSearchOptions)) {
        setSearchOptions([]);
      }
    }
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, []);

  return (
    <div className={styles.header} id="navbar">
      <div className="page">
        <nav className={styles.navbar}>
          <div className={styles.navbarTop}>
            <Link to="/" title={placeholder}>
              <img src={logoSrc} alt={placeholder} className={styles.navbarLogo} />
            </Link>
            <Button 
              icon={<MailOutlined />}
              className={`${styles.newsletter} ${styles.newsletterMobile}`}
              onClick={() => {
                window.open(
                  'https://tinyletter.com/datayears',
                  '_blank', 
                  `scrollbars=yes,width=${window.innerWidth},height=${window.innerHeight}`
                );
              }}
            >
              Newsletter
            </Button>
          </div>
          <div className={styles.navbarRight}>
            <div className={styles.navbarSearchContainer} data-search>
                <input
                  type="text"
                  data-search
                  onBlur={onBlur}
                  onFocus={onFocus}
                  ref={searchInputEl}
                  onKeyDown={onKeyDown}
                  disabled={isSearchLoading}
                  className={styles.navbarSearch}
                  onChange={e => onSearch(e.target.value)}
                  placeholder="Search for interesting data"
                />
                {!isSearchLoading && (
                  <FaSearch
                    data-search
                    className={styles.navbarSearchIcon}
                    onClick={() => searchInputEl.current.focus()}
                  />
                )}
                {isSearchLoading && (
                  <SyncOutlined spin data-search className={styles.navbarSearchIcon} />
                )}

                <ul
                  data-search
                  className={`${styles.navbarSearchOptions} ${isEmpty(searchOptions) ? '' : styles.navbarSearchOptionsOpened}`}
                >
                  {isSearchLoading && (
                    <List.Item
                      data-search
                      className={`${styles.navbarSearchOptionsItem} ${styles.navbarSearchOptionsItemLoading}`}
                    >
                      <List.Item.Meta title={<Spin />} data-search />
                    </List.Item>
                  )}
                  {!isSearchLoading && searchOptions.map(({ text, description, img, linkTo }, i) => (
                    <Tooltip placement="left" title={description} data-search key={`list-item-${i}`}>
                      <Link
                        data-search
                        to={linkTo}
                        className={`${styles.navbarSearchOptionsItem} ${hoveredSearchOption === i ? styles.navbarSearchOptionsItemHovered : ''}`}
                      >
                        <List.Item data-search>
                          <List.Item.Meta title={text} data-search />
                        </List.Item>
                      </Link>
                    </Tooltip>
                  ))}
                </ul>
            </div>

            <Select
              showSearch
              placeholder="Categories"
              optionFilterProp="children"
              className={styles.navbarMenu}
              onChange={category => navigate(`/${category}`)}
              filterOption={(input, option) =>
                lowerCase(option.value).includes(lowerCase(input))
              }
            >
              {[...CATEGORIES].sort().map(category => (
                <Select.Option key={category} value={category}>
                  <Link to={`/${category}`}>{startCase(category)}</Link>
                </Select.Option>
              ))}
            </Select>

            <Tooltip placement="left" title="Subscribe to Datayears Newsletter" className={styles.newsletterDesktop}>
              <Button 
                shape="circle"
                icon={<MailOutlined />}
                className={styles.newsletter}
                onClick={() => {
                  const { left, top } = getLeftTopFromCenter(550, 650);
                  window.open(
                    'https://tinyletter.com/datayears',
                    '_blank', 
                    `scrollbars=yes,left=${left},top=${top},width=550,height=650`
                  );
                }}
              />
            </Tooltip>
          </div>
        </nav>
      </div>
      <Divider className={styles.navbarDivider} />
    </div>
  );
};

export default Navbar;

import React from 'react';
import { Button } from 'antd';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { BsArrowRightShort } from 'react-icons/bs';

import styles from '../index.module.scss';

const ReadMoreButton = ({ linkTo, text, minimal }) => (
    <Button
        href={linkTo}
        className={styles.cardButton}
        size={minimal ? 'default' : 'large'}
        style={minimal ? {marginTop: 0} : {}}
        onClick={async e => {
            e.preventDefault();
            await navigate(linkTo);
        }}
    >
        <span>{text}</span>
        {!minimal && (
            <BsArrowRightShort
                size={26}
                className={styles.cardButtonArrow}
            />
        )}
    </Button>
);

ReadMoreButton.propTypes = {
    linkTo: PropTypes.string,
    text: PropTypes.string,
    minimal: PropTypes.bool,
};

ReadMoreButton.defaultProps = {
    linkTo: '/',
    text: 'Read More',
    minimal: false,
};

export default ReadMoreButton;